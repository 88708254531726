<template>
    <div class="mobHome">
        <div class="content_mp01">
            <transition enter-active-class="animated bounceInDown" leave-active-class="animated slideOutUp">
                <Menu class="id_menu" v-show="page01Show"/>
            </transition>
            <transition enter-active-class="animated slideInDown" leave-active-class="animated slideOutUp">
                <div class="p01_div1" v-show="page01Show">
                    MonsterLand is a monster metaverse that<br/>
                    includes modules such as Defi,<br/>
                    gaming, and social media.<br/>
                </div>
            </transition>
            <transition enter-active-class="animated slideInDown" leave-active-class="animated slideOutUp">
                <div class="p01_div2" v-show="page01Show">
                    MetaID is the identity card of MonsterLand, which is a decentralized identity (account) protocol based on dynamic NFT, and it is a relay of addresses and products in web3.0.
                </div>
            </transition>
            <transition enter-active-class="animated fadeInLeftBig" leave-active-class="animated slideOutUp">
                <div class="p01_div3" v-show="page01Show" @click="toMintPage()">
                    Mint MetaID
                </div>
            </transition>
            <transition enter-active-class="animated slideInDown" leave-active-class="animated slideOutUp">
                <img class="p01_img1" src="../../../assets/images/img_background1.png" v-show="page01Show"/>
            </transition>
            <transition enter-active-class="animated fadeInRight" leave-active-class="animated slideOutUp">
                <img class="p01_img2" src="../../../assets/images/img_background2.png" v-show="page01Show"/>
            </transition>
            <transition enter-active-class="animated fadeInRight" leave-active-class="animated slideOutUp">
                <img class="p01_img3" src="../../../assets/images/img_firstpage_m1.png" v-show="page01Show"/>
            </transition>
        </div>
        <div class="content_mp02">
            <img class="p02_img1" src="../../../assets/images/img_background3_m.png"/>
            <div class="mp02_div1" ref="mp02_div1">
                <img class="p02_img2 com" src="../../../assets/images/img_notebook.png"/>
                <transition enter-active-class="animated fadeInUp" leave-active-class="animated slideOutUp">
                    <div v-show="page02Show">
                        <div class="p02_div11">
                            What is MetaID?
                        </div>
                        <div class="p02_div12" id="p02_div12"></div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="content_mp03">
            <div class="mp03_div1" ref="mp03_div1">
                <transition enter-active-class="animated lightSpeedIn" leave-active-class="animated slideOutUp">
                    <div class="p03_div11" v-show="page0310Show">MetaID Features</div>
                </transition>
                <transition enter-active-class="animated bounceInDown" leave-active-class="animated slideOutUp">
                    <div class="p03_div12" ref="p03_div12" v-show="page0313Show">
                        <transition enter-active-class="animated fadeInUp" leave-active-class="animated slideOutUp">
                            <div id="m301" class="ball_01" ref="ball_01" v-show="page031Show" @click="playBall(),toSetInfo(1)">Level</div>
                        </transition>
                        <transition enter-active-class="animated fadeInUp" leave-active-class="animated slideOutUp">
                            <div id="m302" class="ball_02" ref="ball_02" v-show="page032Show" @click="playBall(),toSetInfo(2)">Open</div>
                        </transition>
                        <transition enter-active-class="animated fadeInUp" leave-active-class="animated slideOutUp">
                            <div id="m303" class="ball_03" ref="ball_03" v-show="page033Show" @click="playBall(),toSetInfo(3)">Data</div>
                        </transition>
                        <transition enter-active-class="animated fadeInUp" leave-active-class="animated slideOutUp">
                            <div id="m304" class="ball_04" ref="ball_04" v-show="page034Show" @click="playBall(),toSetInfo(4)">DID</div>
                        </transition>
                        <transition enter-active-class="animated fadeInUp" leave-active-class="animated slideOutUp">
                            <div id="m305" class="ball_05" ref="ball_05" v-show="page035Show" @click="playBall(),toSetInfo(5)">Bank</div>
                        </transition>
                        <transition enter-active-class="animated fadeInUp" leave-active-class="animated slideOutUp">
                            <div id="m306" class="ball_06" ref="ball_06" v-show="page036Show" @click="playBall(),toSetInfo(6)">dNFT</div>
                        </transition>
                        <transition enter-active-class="animated fadeInUp" leave-active-class="animated slideOutUp">
                            <div id="m307" class="ball_07" ref="ball_07" v-show="page037Show" @click="playBall(),toSetInfo(7)">Transfer</div>
                        </transition>
                        <transition enter-active-class="animated fadeInUp" leave-active-class="animated slideOutUp">
                            <div id="m308" class="ball_08" ref="ball_08" v-show="page038Show" @click="playBall(),toSetInfo(8)">Earn</div>
                        </transition>
                        <transition enter-active-class="animated fadeInUp" leave-active-class="animated slideOutUp">
                            <div id="m309" class="ball_09" v-show="page039Show" @click="playBall(),toSetInfo(9)">Avatar</div>
                        </transition>
                    </div>
                </transition>
            </div>
            <div class="mp03_div2" ref="mp03_div2">
                <transition enter-active-class="animated rotateIn" leave-active-class="animated slideOutUp">
                    <img class="p03_img1 com" v-show="page0311Show" src="../../../assets/images/img_arrow.png"/>
                </transition>
                <transition enter-active-class="animated fadeInUp" leave-active-class="animated slideOutUp">
                    <div class="p03_div21" v-show="page0312Show">
                        <div class="p03_div211">
                            {{ p03Title }}
                        </div>
                        <div class="p03_div212">
                            {{ p03Text }}
                        </div>
                        <div class="p03_div213">
                            LEARN MORE
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="content_mp04">
            <div class="mp04_div01" ref="mp04_div01" id="mp04_div01">
                <transition enter-active-class="animated slideInLeft" leave-active-class="animated slideOutUp">
                    <div v-show="page04Show">METAID SHOW</div>
                </transition>
                <transition enter-active-class="animated slideInRight" leave-active-class="animated slideOutUp">
                    <img v-show="page04Show" src="../../../assets/images/img_background4.png"/>
                </transition>
            </div>
            <transition enter-active-class="animated fadeInUp" leave-active-class="animated slideOutUp">
                <img v-show="page04Show" class="p04_img01" src="../../../assets/images/img_card1_1.png" @click="toPage041Show()"/>
            </transition>
            <transition enter-active-class="animated rubberBand" leave-active-class="animated slideOutUp">
                <img v-show="page041Show" class="p04_img02" src="../../../assets/images/img_card2_m.png"/>
            </transition>
            <transition enter-active-class="animated rubberBand" leave-active-class="animated bounceOut">
                <img v-show="page04Show && !page041Show" class="p04_img03" src="../../../assets/images/img_click.png" @click="toPage041Show()"/>
            </transition>
        </div>
        <div class="content_mp05">
            <div class="p05_div01">
                <div class="p05_div01_title">MetaID Value</div>
                <div class="mp05_div01_infos" ref="mp05_div01_infos" id="mp05_div01_infos">
                    <div class="p05_div01_info">
                        <transition enter-active-class="animated flipInY" leave-active-class="animated slideOutUp">
                            <img v-show="page051Show" src="../../../assets/images/img_value1.png"/>
                        </transition>
                        <div class="tu_img" v-if="!page051Show"/>
                        <div class="text_title">Dividend</div>
                        <transition enter-active-class="animated slideInDown" leave-active-class="animated slideOutUp">
                            <div v-show="page051Show" class="text_data">Holders of MetaID will receive the distribution of platform tokens, thereby capturing the ecological value of the platform.</div>
                        </transition>
                        <div class="text_data" v-if="!page051Show"/>
                    </div>
                    <div class="p05_div01_info">
                        <transition enter-active-class="animated flipInY" leave-active-class="animated slideOutUp">
                            <img v-show="page051Show" src="../../../assets/images/img_value2.png"/>
                        </transition>
                        <div class="tu_img" v-if="!page051Show"/>
                        <div class="text_title">Growth</div>
                        <transition enter-active-class="animated slideInDown" leave-active-class="animated slideOutUp">
                            <div v-show="page051Show" class="text_data">With the accumulation of MetaID's active level, game data, and social data, MetaID will also increase in value</div>
                        </transition>
                        <div class="text_data" v-if="!page051Show"/>
                    </div>
                    <div class="p05_div01_info">
                        <transition enter-active-class="animated flipInY" leave-active-class="animated slideOutUp">
                            <img v-show="page051Show" src="../../../assets/images/img_value3.png"/>
                        </transition>
                        <div class="tu_img" v-if="!page051Show"/>
                        <div class="text_title">Art</div>
                        <transition enter-active-class="animated slideInDown" leave-active-class="animated slideOutUp">
                            <div v-show="page051Show" class="text_data">MetaID's editable Avatar feature allows holder and artists to create on MetaID to capture artistic value.</div>
                        </transition>
                        <div class="text_data" v-if="!page051Show"/>
                    </div>
                    <div class="p05_div01_info">
                        <transition enter-active-class="animated flipInY" leave-active-class="animated slideOutUp">
                            <img v-show="page051Show" src="../../../assets/images/img_value4.png"/>
                        </transition>
                        <div class="tu_img" v-if="!page051Show"/>
                        <div class="text_title">Extra</div>
                        <transition enter-active-class="animated slideInDown" leave-active-class="animated slideOutUp">
                            <div v-show="page051Show" class="text_data">NFT, token airdrop,IGO /IDO white list qualification of products in MonsterLand ecology</div>
                        </transition>
                        <div class="text_data" v-if="!page051Show"/>
                    </div>
                </div>
            </div>
            <div class="p05_div02">
                <div class="p05_div02_title">MetaID Application</div>
                <div class="mp05_div02_text" ref="mp05_div02_text" id="mp05_div02_text">The holder uses metaid to login to any Dapp accessing SDK and API, and authorizes it to use identity, avatar, game data, social relations and other information.</div>
                <div class="p05_div02_info">
                    <transition enter-active-class="animated flipInX" leave-active-class="animated slideOutUp">
                        <div class="p05_div02_info1" v-show="page052Show">
                            <div class="div02_info_title">Games</div>
                            <div class="div02_info_text">
                                Use MetaID to login to the game with one click. Avatars and game data will be presented or displayed in the game.
                                Friends' game information can also be viewed and compared with each other.
                            </div>
                        </div>
                    </transition>
                    <transition enter-active-class="animated flipInX" leave-active-class="animated slideOutUp">
                        <div class="p05_div02_info2" v-show="page052Show">
                            <div class="div02_info_title">Social</div>
                            <div class="div02_info_text">
                                In socialfi products, you can use virtual images to chat, discuss and interact with friends in MetaID social relationships.
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
        <div class="content_mp06">
            <img class="p06_img01" src="../../../assets/images/img_background5_m.png"/>
            <div class="p06_div01">
                <div class="mp06_div011" ref="mp06_div011" id="mp06_div011">
                    <transition enter-active-class="animated rotateInDownLeft" leave-active-class="animated slideOutUp">
                        <div class="p06_div0111" v-show="page06Show">How Many MetaIDs</div>
                    </transition>
                    <transition enter-active-class="animated rotateInDownRight" leave-active-class="animated slideOutUp">
                        <div class="p06_div0112" v-show="page06Show">Have Been Minted?</div>
                    </transition>
                    <div class="p06_div0113" v-if="!page06Show"></div>
                </div>
                <div class="mp06_div012" ref="mp06_div012" id="mp06_div012">
                    <div class="p06_div_info" v-for="(num, index) in statisticalNumArray" :key="index">
                        <NumRunout v-if="num !== ','" :value="parseInt(num)"/>
                        <div v-else>{{ num }}</div>
                    </div>
                </div>
                <div class="mp06_div013" ref="mp06_div013" @click="toMintPage()">Mint MetaID</div>
            </div>
        </div>
        <div class="content_mp07" id="content_mp07">
            <transition enter-active-class="animated fadeInRight" leave-active-class="animated slideOutUp">
                <Bottom v-show="page07Show"/>
            </transition>
        </div>
    </div>
</template>

<script>
    import Menu from "../../../components/framing/Menu";
    import Bottom from "../../../components/framing/Bottom";
    import NumRunout from "../../../components/framing/NumRunout";
    import toolUtils from "../../../tools/toolUtils";
    import * as metaMaskTools from "../../../tools/chain";
    import transfer from "../../../tools/transfer";

    export default {
        name: "Index",
        components: {Menu, Bottom, NumRunout},
        data() {
            return {
                page01Show: false,
                page02Show: false,
                page031Show: false,
                page032Show: false,
                page033Show: false,
                page034Show: false,
                page035Show: false,
                page036Show: false,
                page037Show: false,
                page038Show: false,
                page039Show: false,
                page0310Show: false,
                page0311Show: false,
                page0312Show: false,
                page0313Show: false,
                page04Show: false,
                page041Show: false,
                page051Show: false,
                page052Show: false,
                page06Show: false,
                page07Show: false,
                isPage06: false,
                isPlayBall: false,
                p03Title: 'Decentralized Digital Identity',
                p03Text: 'Each NFT is an independent identity (account) and is unique. The MetaID is solely held by the user and no one can change or transfer it except the owner.',
                statisticalNum: 0,
                statisticalNumArray: ['0','0','0',',','0','0','0',',','0','0','0']
            }
        },
        watch: {},
        created() {
            window.addEventListener("scroll", this.scrollToTopMob);
        },
        mounted() {
            setTimeout(() => {
                this.page01Show = true;
            }, 200);

            this.getNftTotalSupply();
        },
        destroyed(){
            window.removeEventListener('scroll', this.scrollToTopMob);
        },
        methods: {
            async getNftTotalSupply() {
                try {
                    await metaMaskTools.metaIdPublicCall("totalSupply", []).then(async (data) => {
                        this.statisticalNum = parseInt(data);

                        setTimeout(() => {
                            this.getNftTotalSupply();
                        }, 3000);
                    });
                } catch (err) {
                    console.log(err);
                }
            },
            scrollToTopMob(){
                let that = this;
                // type==1表示可见，type==0表示不可见
                let osv = new toolUtils.osVisible();

                osv.init("#mp06_div012", function (type, id) {
                    if (type === 1) {
                        that.getStatisticalInfo();
                    } else {
                        // this.isPage06 = false;
                        // this.statisticalNumArray = ['0','0','0',',','0','0','0',',','0','0','0'];
                    }
                }, 1)

                if (!toolUtils.isElementNotInViewport(this.$refs.mp02_div1)) {
                    setTimeout(() => {
                        if (!this.page02Show) {
                            setTimeout(() => {
                                this.toTyping();
                            }, 500)
                        }
                        this.page02Show = true;
                    }, 450);
                }
                if (!toolUtils.isElementNotInViewport(this.$refs.mp03_div1)) {
                    setTimeout(() => {
                        this.page0310Show = true;
                        this.page0313Show = true;
                        if (this.page0313Show) {
                            setTimeout(() => {
                                this.page031Show = true;
                                setTimeout(() => {
                                    this.page032Show = true;
                                    setTimeout(() => {
                                        this.page033Show = true;
                                        setTimeout(() => {
                                            this.page034Show = true;
                                            setTimeout(() => {
                                                this.page035Show = true;
                                                setTimeout(() => {
                                                    this.page036Show = true;
                                                    setTimeout(() => {
                                                        this.page037Show = true;
                                                        setTimeout(() => {
                                                            this.page038Show = true;
                                                            setTimeout(() => {
                                                                this.page039Show = true;
                                                            }, 200);
                                                        }, 200);
                                                    }, 200);
                                                }, 200);
                                            }, 200);
                                        }, 200);
                                    }, 200);
                                }, 200);
                            }, 200);
                        }
                    }, 300);
                }
                if (!toolUtils.isElementNotInViewport(this.$refs.mp03_div2)) {
                    setTimeout(() => {
                        this.page0311Show = true;
                        this.page0312Show = true;
                    }, 300);
                }

                osv.init("#mp04_div01", function (type, id) {
                    if (type === 1) {
                        setTimeout(() => {
                            that.page04Show = true;
                        }, 300);
                    }
                }, 1)

                osv.init("#mp05_div01_infos", function (type, id) {
                    if (type === 1) {
                        setTimeout(() => {
                            that.page051Show = true;
                        }, 300);
                    }
                }, 1)

                osv.init("#mp05_div02_text", function (type, id) {
                    if (type === 1) {
                        setTimeout(() => {
                            that.page052Show = true;
                        }, 300);
                    }
                }, 1)

                osv.init("#mp06_div011", function (type, id) {
                    if (type === 1) {
                        setTimeout(() => {
                            that.page06Show = true;
                        }, 300);
                    }
                }, 1)

                osv.init("#content_mp07", function (type, id) {
                    if (type === 1) {
                        setTimeout(() => {
                            that.page07Show = true;
                        }, 300);
                    }
                }, 1)
            },
            toTyping() {
                var myDiv = document.getElementById("p02_div12");

                var contentArr = "<p>MetaID is a decentralized identity (account) protocol launched by the MonsterLand platform with dynamic NFT as the underlying layer. The irreplaceable, programmable and transferable characteristics of NFT are naturally suitable as a carrier of decentralized identity (DID).\n" +
                    "                            <p/>\n" +
                    "                            MetaID was born on the basis of these characteristics of NFT. The expanded content covers asset storage, data storage, Editable avatars, etc., make them have asset, data and artistic precipitation value.\n" +
                    "                            <p/>\n" +
                    "                            MetaID is an open source and auditing protocol that allows access and expansion of external protocols, which makes it open, secure and extensible.\n" +
                    "                            <p/>\n" +
                    "                            MetaID will be widely used in games, social networking and other scenarios. It is an important basic implementation of Web3.0, so that digital assets are truly in the hands of users.\n" +
                    "                            <p/>\n" +
                    "                            At the same time, MetaID, as one of the core products of the MonsterLand platform, will be the \"golden shovel\" that captures a lot of platform value.\n" +
                    "\n".split("");

                var content = "";
                var index = 0;

                var ID = setInterval(function () {

                    content += contentArr[index];
                    myDiv.innerHTML = content + "_";
                    index++;

                    if(index === contentArr.length){
                        myDiv.innerHTML = content ;
                        clearInterval(ID);
                    }
                },20);
            },
            toPage041Show() {
                this.page041Show = true;
            },
            formatNumber(num, precision, separator) {
                return toolUtils.formatNumber(num, precision, separator);
            },
            getStatisticalInfo() {
                if (!this.isPage06) {
                    this.isPage06 = true;
                    // setTimeout(() => {
                    //     if (this.statisticalNum > 0) {
                    //         let statisticalText = this.formatNumber(this.statisticalNum, 0, ',');
                    //         let numLength = statisticalText.split('').length;
                    //         let arrLength = this.statisticalNumArray.length;
                    //         let difference = arrLength - numLength;
                    //         for (let i = 0; i < numLength; i++) {
                    //             this.$set(this.statisticalNumArray, (difference + i), statisticalText.split('')[i]);
                    //         }
                    //     }
                    // }, 500)
                }
            },
            toSetInfo(num) {
                if (num === 1) {
                    this.p03Title = 'Active Level';
                    this.p03Text = 'MetaID has an attribute similar to "contribution value" - activity, and the initial value is 0. Users can increase their activity and improve their MetaID level by playing, interacting, socializing and participating in Defi on the MonsterLand platform.';
                } else if (num === 2) {
                    this.p03Title = 'Openness';
                    this.p03Text = 'MetaID is an open source protocol that provides external operation interfaces and data interfaces such as Mint, avatar editing, deposits and withdrawals, and transfers. Any project can be freely accessed and expanded.';
                } else if (num === 3) {
                    this.p03Title = 'Data Storage';
                    this.p03Text = 'MetaID adopts a unique algorithm and method to solve the problem of limited data length in NFT contract records. It uses distributed records and index tracking methods to enable unlimited data records on NFT, including game data and social relations.';
                } else if (num === 4) {
                    this.p03Title = 'Decentralized Digital Identity';
                    this.p03Text = 'Each NFT is an independent identity (account) and is unique. The MetaID is solely held by the user and no one can change or transfer it except the owner.';
                } else if (num === 5) {
                    this.p03Title = 'NFT Bank';
                    this.p03Text = 'MetaID has the ability to store any asset (FT or NFT) and follow the transfer. MetaID holders can freely deposit assets into MetaID or withdraw assets from MetaID. Holders can completely sell Erc20 assets by trading MetaID without incurring slippage.';
                } else if (num === 6) {
                    this.p03Title = 'Dynamic NFT';
                    this.p03Text = 'Each MetaID is an NFT, and the NFT is a dynamic NFT (dNFT) with the ability to "adapt and change in response to external events and data".';
                } else if (num === 7) {
                    this.p03Title = 'Transferable';
                    this.p03Text = 'The underlying properties of MetaID\'s NFT determine its transferable characteristics. Once transferred, whoever holds MetaID will also own all the tokens and data assets it carries, including deposited tokens, game data, social relations, activity, etc.';
                } else if (num === 8) {
                    this.p03Title = 'Earner';
                    this.p03Text = 'MetaID holders can passively capture the revenue of the MonsterLand platform, which has nothing to do with the holders, but is related to the MetaID index, which is calculated from the rarity, algebra, social participation and activity of MetaID. At the same time, MetaID can be used as a threshold for game or product access, whitelist, and airdrop.';
                } else if (num === 9) {
                    this.p03Title = 'Editable Avatar';
                    this.p03Text = 'Each MetaID is an avatar NFT, but its presentation is no longer an immutable static image. The MetaID holder can become an artist to DIY on the MetaID, and replace the corresponding parts on the avatar with their own design resources (such as skin, hairstyle, eyes, nose, etc.) to create a one-of-a-kind Avatar.';
                }
            },
            playBall() {
                if (this.isPlayBall) {
                    return;
                } else {
                    this.isPlayBall = true;
                    $('#m301').animate({left: '460px', top: '225px'}, 200);
                    $('#m302').animate({left: '406px', top: '102px'}, 200);
                    $('#m303').animate({left: '213px', top: '0px'}, 200);
                    $('#m304').animate({left: '268px', top: '10px'}, 200);
                    $('#m305').animate({left: '380px', top: '309px'}, 200);
                    $('#m306').animate({left: '52px', top: '52px'}, 200);
                    $('#m307').animate({left: '299px', top: '13px'}, 200);
                    $('#m308').animate({left: '315px', top: '411px'}, 200);
                    $('#m309').animate({left: '108px', top: '385px'}, 200);
                    setTimeout(() => {
                        $('#m301').animate({left: '93px', top: '415px'}, 200);
                        $('#m302').animate({left: '147px', top: '426px'}, 200);
                        $('#m303').animate({left: '302px', top: '385px'}, 200);
                        $('#m304').animate({left: '400px', top: '283px'}, 200);
                        $('#m305').animate({left: '13px', top: '134px'}, 200);
                        $('#m306').animate({left: '68px', top: '313px'}, 200);
                        $('#m307').animate({left: '45px', top: '365px'}, 200);
                        $('#m308').animate({left: '15px', top: '138px'}, 200);
                        $('#m309').animate({left: '8px', top: '152px'}, 200);
                    }, 200);
                    setTimeout(() => {
                        $('#m301').animate({left: '205px', top: '3px'}, 200);
                        $('#m302').animate({left: '425px', top: '298px'}, 200);
                        $('#m303').animate({left: '8px', top: '151px'}, 200);
                        $('#m304').animate({left: '20px', top: '119px'}, 200);
                        $('#m305').animate({left: '363px', top: '334px'}, 200);
                        $('#m306').animate({left: '290px', top: '45px'}, 200);
                        $('#m307').animate({left: '407px', top: '85px'}, 200);
                        $('#m308').animate({left: '340px', top: '38px'}, 200);
                        $('#m309').animate({left: '406px', top: '240px'}, 200);
                    }, 350);
                    setTimeout(() => {
                        $('#m301').animate({left: '418px', top: '361px'}, 250);
                        $('#m302').animate({left: '378px', top: '70px'}, 250);
                        $('#m303').animate({left: '389px', top: '114px'}, 250);
                        $('#m304').animate({left: '268px', top: '406px'}, 250);
                        $('#m305').animate({left: '363px', top: '77px'}, 250);
                        $('#m306').animate({left: '97px', top: '332px'}, 250);
                        $('#m307').animate({left: '29px', top: '339px'}, 250);
                        $('#m308').animate({left: '124px', top: '414px'}, 250);
                        $('#m309').animate({left: '45px', top: '332px'}, 250);
                    }, 550);
                    setTimeout(() => {
                        $('#m301').animate({left: '80px', top: '58px'}, 300);
                        $('#m302').animate({left: '37px', top: '99px'}, 300);
                        $('#m303').animate({left: '347px', top: '352px'}, 300);
                        $('#m304').animate({left: '306px', top: '25px'}, 300);
                        $('#m305').animate({left: '228px', top: '408px'}, 300);
                        $('#m306').animate({left: '345px', top: '136px'}, 300);
                        $('#m307').animate({left: '447px', top: '156px'}, 300);
                        $('#m308').animate({left: '109px', top: '29px'}, 300);
                        $('#m309').animate({left: '189px', top: '2px'}, 300);
                    }, 800);
                    setTimeout(() => {
                        $('#m301').animate({left: '0', top: '225px'}, 350);
                        $('#m302').animate({left: '25px', top: '320px'}, 350);
                        $('#m303').animate({left: '117px', top: '390px'}, 350);
                        $('#m304').animate({left: '78px', top: '130px'}, 350);
                        $('#m305').animate({left: '163px', top: '247px'}, 350);
                        $('#m306').animate({left: '290px', top: '307px'}, 350);
                        $('#m307').animate({left: '299px', top: '213px'}, 350);
                        $('#m308').animate({left: '323px', top: '93px'}, 350);
                        $('#m309').animate({left: '408px', top: '179px'}, 350);
                    }, 1100);
                }
                setTimeout(() => {
                    this.isPlayBall = false;
                }, 1200);
            },
            toMintMetaId() {
                this.$message.warning('Coming soon!');
            },
            toMintPage() {
                transfer.$emit("toMenuPage", 2);
            }
        }
    }
</script>

<style lang="less">
    .mobHome {
        background-color: rgba(255, 255, 255, 1);
        position: relative;
        width: 100%;
        .content_mp01 {
            width: 100%;
            height: 1020px;
            background: #141315;
            position: relative;
            z-index: 20;
            clip-path: polygon(0% 0%, 100% 0%, 100% 93%, 0% 100%);
            .id_menu {
                position: absolute;
                top: 10px;
                z-index: 10;
            }
            .p01_div1 {
                position: absolute;
                top: 410px;
                height: 220px;
                left: 10%;
                font-size: 29px;
                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                font-weight: bold;
                color: #F9F7F3;
                line-height: 32px;
                text-shadow: -5px 4px 0px #141315;
                -webkit-text-stroke: 2px #141315;
                text-stroke: 4px #141315;
                z-index: 10;
            }
            .p01_div2 {
                position: absolute;
                right: 10%;
                top: 170px;
                width: 300px;
                height: 180px;
                font-size: 20px;
                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                font-weight: bold;
                color: #F9F7F3;
                line-height: 32px;
                text-align: right;
            }
            .p01_div3 {
                position: absolute;
                left: 10%;
                top: 620px;
                width: 285px;
                height: 86px;
                background: url("../../../assets/images/btn_purple1.png");
                background-size: 100% 100%;
                text-align: center;
                color: white;
                font-weight: 600;
                font-size: 30px;
                font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                line-height: 80px;
                cursor: pointer;
                z-index: 10;
            }
            .p01_img1 {
                width: 50%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 9;
            }
            .p01_img2 {
                width: 87%;
                position: absolute;
                right: 0;
                bottom: 0;
            }
            .p01_img3 {
                width: 100%;
                position: absolute;
                bottom: -20px;
                right: 0;
                left: 0;
            }
        }
        .content_mp02 {
            width: 100%;
            height: 1200px;
            background: #FBEFE0;
            position: relative;
            margin-top: -75px;
            z-index: 19;
            .p02_img1 {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 9;
            }
            .mp02_div1 {
                position: absolute;
                top: calc((100% - 930px) / 2);
                left: calc((100% - 400px) / 2);
                width: 400px;
                height: 930px;
                background: #FFFFFF;
                box-shadow: -8px 8px 0px 0px #141315;
                border-radius: 30px;
                border: 6px solid #141315;
                z-index: 10;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: flex-start;
                justify-content: space-between;
                .p02_img2 {
                    margin-top: calc((830px - 600px) / 2);
                    margin-left: 25px;
                    height: 600px;
                }
                div {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-content: flex-start;
                    align-items: flex-start;
                    justify-content: space-between;
                    margin: 10px 10px 10px 10px;
                    .p02_div11 {
                        height: 64px;
                        font-size: 40px;
                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-weight: bold;
                        color: #141315;
                        line-height: 64px;
                        margin: 30px 10px 10px 10px;
                    }
                    .p02_div12 {
                        width: 350px;
                        font-size: 18px;
                        font-family: SpaceGrotesk-Medium, SpaceGrotesk;
                        font-weight: 600;
                        color: #141315;
                        line-height: 20px;
                        margin-top: 0px;
                    }
                }
            }
        }
        .content_mp03 {
            width: 100%;
            height: 1200px;
            color: white;
            background: #141315;
            position: relative;
            margin-top: -85px;
            z-index: 19;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            clip-path: polygon(0% 0%, 100% 7%, 100% 100%, 0% 100%);
            .mp03_div1 {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: flex-start;
                margin-top: 120px;
                margin-left: calc((100% - 405px) / 2);
                .p03_div11 {
                    height: 100px;
                    font-size: 46px;
                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                    font-weight: bold;
                    color: #F9F7F3;
                    line-height: 115px;
                }
                .p03_div12 {
                    zoom: 0.72;
                    width: 560px;
                    height: 560px;
                    background: #FBEFE0;
                    border-radius: 50%;
                    margin-top: 100px;
                    position: relative;
                    .ball_01 {
                        width: 100px;
                        height: 100px;
                        background: #59B0DF;
                        border-radius: 50%;
                        text-align: center;
                        font-size: 20px;
                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-weight: bold;
                        line-height: 100px;
                        position: absolute;
                        left: 0;
                        top: 225px;
                        cursor: pointer;
                    }
                    .ball_02 {
                        width: 120px;
                        height: 120px;
                        background: #F7A072;
                        border-radius: 50%;
                        text-align: center;
                        font-size: 20px;
                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-weight: bold;
                        line-height: 120px;
                        position: absolute;
                        left: 25px;
                        top: 320px;
                        cursor: pointer;
                    }
                    .ball_03 {
                        width: 150px;
                        height: 150px;
                        background: #141315;
                        border-radius: 50%;
                        text-align: center;
                        font-size: 20px;
                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-weight: bold;
                        line-height: 150px;
                        position: absolute;
                        left: 117px;
                        top: 390px;
                        cursor: pointer;
                    }
                    .ball_04 {
                        width: 145px;
                        height: 145px;
                        background: #141315;
                        border-radius: 50%;
                        text-align: center;
                        font-size: 20px;
                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-weight: bold;
                        line-height: 145px;
                        position: absolute;
                        left: 78px;
                        top: 130px;
                        cursor: pointer;
                    }
                    .ball_05 {
                        width: 150px;
                        height: 150px;
                        background: #59B0DF;
                        border-radius: 50%;
                        text-align: center;
                        font-size: 20px;
                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-weight: bold;
                        line-height: 150px;
                        position: absolute;
                        left: 163px;
                        top: 247px;
                        cursor: pointer;
                    }
                    .ball_06 {
                        width: 210px;
                        height: 210px;
                        background: #141315;
                        border-radius: 50%;
                        text-align: center;
                        font-size: 20px;
                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-weight: bold;
                        line-height: 210px;
                        position: absolute;
                        left: 290px;
                        top: 307px;
                        cursor: pointer;
                    }
                    .ball_07 {
                        width: 100px;
                        height: 100px;
                        background: #7D69EC;
                        border-radius: 50%;
                        text-align: center;
                        font-size: 20px;
                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-weight: bold;
                        line-height: 100px;
                        position: absolute;
                        left: 299px;
                        top: 213px;
                        cursor: pointer;
                    }
                    .ball_08 {
                        width: 125px;
                        height: 125px;
                        background: #141315;
                        border-radius: 50%;
                        text-align: center;
                        font-size: 20px;
                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-weight: bold;
                        line-height: 125px;
                        position: absolute;
                        left: 323px;
                        top: 93px;
                        cursor: pointer;
                    }
                    .ball_09 {
                        width: 150px;
                        height: 150px;
                        background: #F7A072;
                        border-radius: 50%;
                        text-align: center;
                        font-size: 20px;
                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-weight: bold;
                        line-height: 150px;
                        position: absolute;
                        left: 408px;
                        top: 179px;
                        cursor: pointer;
                    }
                }
            }
            .mp03_div2 {
                margin-top: 20px;
                margin-bottom: 50px;
                margin-left: calc((100% - 410px) / 2);
                .p03_img1 {
                    margin-left: -50px;
                }
                .p03_div21 {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: flex-start;
                    .p03_div211 {
                        height: 50px;
                        font-size: 25px;
                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-weight: bold;
                        color: #F9F7F3;
                        line-height: 50px;
                        margin-top: 45px;
                    }
                    .p03_div212 {
                        height: 145px;
                        font-size: 17px;
                        font-family: SpaceGrotesk-Regular, SpaceGrotesk;
                        font-weight: 400;
                        color: #F9F7F3;
                        line-height: 23px;
                        margin: 45px 15px 45px 0;
                    }
                    .p03_div213 {
                        width: 165px;
                        height: 30px;
                        font-size: 21px;
                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-weight: bold;
                        color: #F9F7F3;
                        line-height: 31px;
                        text-decoration: underline;
                    }
                }
            }
        }
        .content_mp04 {
            width: 100%;
            height: 960px;
            background: #B6E2FA;
            position: relative;
            margin-top: -70px;
            z-index: 19;
            clip-path: polygon(0% 7%, 100% 0%, 100% 100%, 0% 100%);
            .mp04_div01 {
                position: absolute;
                top: 80px;
                text-align: center;
                width: 100%;
                div {
                    width: 90%;
                    height: 260px;
                    font-size: 80px;
                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                    font-weight: bold;
                    color: #F9F7F3;
                    line-height: 70px;
                    text-align: right;
                    margin-top: 20px;
                }
                img {
                    width: 420px;
                    margin-top: 170px;
                }
            }
            .p04_img01 {
                width: 380px;
                height: 610px;
                position: absolute;
                top: 220px;
                left: calc(((100% - 380px) / 2) - 2px);
            }
            .p04_img02 {
                width: 445px;
                height: 650px;
                position: absolute;
                top: 205px;
                left: calc((100% - 445px) / 2);
            }
            .p04_img03 {
                width: 150px;
                position: absolute;
                top: 570px;
                right: calc(((100% - 380px) / 2) + 5px);
            }
        }
        .content_mp05 {
            width: 100%;
            height: 1820px;
            background: #F9F7F3;
            position: relative;
            .p05_div01 {
                width: 90%;
                margin-left: 5%;
                .p05_div01_title {
                    height: 260px;
                    font-size: 50px;
                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                    font-weight: bold;
                    color: #141315;
                    line-height: 260px;
                }
                .mp05_div01_infos {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-top: 5px;
                    width: 100%;
                    .p05_div01_info {
                        width: 47%;
                        .text_title {
                            height: 35px;
                            font-size: 32px;
                            font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                            font-weight: bold;
                            color: #141315;
                            line-height: 35px;
                            margin-top: 10px;
                        }
                        .text_data {
                            width: 100%;
                            height: 105px;
                            font-size: 16px;
                            font-family: SpaceGrotesk-Medium, SpaceGrotesk;
                            font-weight: 600;
                            color: #141315;
                            line-height: 21px;
                            margin: 50px 0 50px 0;
                        }
                        img {
                            width: 54px;
                            height: 54px;
                        }
                        .tu_img {
                            width: 54px;
                            height: 54px;
                        }
                    }
                }
            }
            .p05_div02 {
                width: 90%;
                margin-left: 5%;
                .p05_div02_title {
                    height: 150px;
                    font-size: 50px;
                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                    font-weight: bold;
                    color: #141315;
                    line-height: 85px;
                    margin-top: 50px;
                }
                .mp05_div02_text {
                    font-size: 16px;
                    font-family: SpaceGrotesk-Medium, SpaceGrotesk;
                    font-weight: 600;
                    color: #141315;
                    line-height: 21px;
                    margin: 60px 0 50px 0;
                }
                .p05_div02_info {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: flex-start;
                    height: 470px;
                    .p05_div02_info1 {
                        width: 630px;
                        height: 320px;
                        zoom: 0.65;
                        background: url("../../../assets/images/img_app1_m.png");
                        background-size: 100% 100%;
                        display: flex;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        align-items: flex-start;
                        .div02_info_title {
                            height: 102px;
                            font-size: 80px;
                            font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                            font-weight: bold;
                            color: #F9F7F3;
                            line-height: 102px;
                            margin: 25px 45px;
                        }
                        .div02_info_text {
                            width: 480px;
                            height: 84px;
                            font-size: 20px;
                            font-family: SpaceGrotesk-Medium, SpaceGrotesk;
                            font-weight: 500;
                            color: #F9F7F3;
                            line-height: 21px;
                            text-shadow: 0px 1px 0px #141315;
                            margin: 45px 45px;
                        }
                    }
                    .p05_div02_info2 {
                        width: 630px;
                        height: 320px;
                        zoom: 0.65;
                        background: url("../../../assets/images/img_app2_m.png");
                        background-size: 100% 100%;
                        display: flex;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        align-items: flex-start;
                        .div02_info_title {
                            height: 102px;
                            font-size: 80px;
                            font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                            font-weight: bold;
                            color: #F9F7F3;
                            line-height: 102px;
                            margin: 25px 45px;
                        }
                        .div02_info_text {
                            width: 480px;
                            height: 84px;
                            font-size: 20px;
                            font-family: SpaceGrotesk-Medium, SpaceGrotesk;
                            font-weight: 500;
                            color: #F9F7F3;
                            line-height: 21px;
                            text-shadow: 0px 1px 0px #141315;
                            margin: 45px 45px;
                        }
                    }
                }
            }
        }
        .content_mp06 {
            width: 100%;
            height: 430px;
            background: #7662EA;
            position: relative;
            clip-path: polygon(0% 7%, 100% 0%, 100% 100%, 0% 100%);
            .p06_img01 {
                position: absolute;
                top: 20px;
                width: 100%;
                z-index: 9;
            }
            .p06_div01 {
                width: 90%;
                margin-left: 5%;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: flex-start;
                position: relative;
                top: 100px;
                z-index: 10;
                .mp06_div011{
                    font-size: 40px;
                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                    font-weight: bold;
                    line-height: 50px;
                    .p06_div0111 {
                        color: #F9F7F3;
                    }
                    .p06_div0112 {
                        color: #B6E2FA;
                    }
                    .p06_div0113 {
                        width: 370px;
                        height: 100px;
                    }
                }
                .mp06_div012 {
                    zoom: 0.35;
                    margin: 100px 0;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: center;
                    align-items: center;
                    .p06_div_info {
                        width: 90px;
                        height: 90px;
                        background: #806EEB;
                        border-radius: 12px;
                        font-size: 60px;
                        font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                        font-weight: bold;
                        color: #FFFFFF;
                        line-height: 90px;
                        margin: 0 9px;
                        div {
                            text-align: center;
                        }
                    }
                }
                .mp06_div013 {
                    zoom: 0.75;
                    text-align: center;
                    width: 306px;
                    height: 86px;
                    background: url("../../../assets/images/btn_white1.png");
                    background-size: 100% 100%;
                    font-size: 30px;
                    font-family: SpaceGrotesk-Bold, SpaceGrotesk;
                    font-weight: bold;
                    color: #141315;
                    line-height: 82px;
                    cursor: pointer;
                }
            }
        }
        .content_mp07 {
            width: 100%;
            height: 240px;
            background: #070707;
        }
    }
</style>
