<template>
    <div class="qwNumBox">
        <div class="trasBox" :style="{ top:( -1 * value * 90) +'px' }">
            <div class="qwnum">0</div>
            <div class="qwnum">1</div>
            <div class="qwnum">2</div>
            <div class="qwnum">3</div>
            <div class="qwnum">4</div>
            <div class="qwnum">5</div>
            <div class="qwnum">6</div>
            <div class="qwnum">7</div>
            <div class="qwnum">8</div>
            <div class="qwnum">9</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NumRunout",
        props: {
            value: {
                type: Number,
                default() {
                    return 0;
                }
            }
        }
    }
</script>

<style lang="less">
    .qwNumBox + .qwNumBox {
        margin-left: 10px;
    }
    .qwNumBox {
        position: relative;
        display: inline-block;
        float: left;
        width: 90px;
        height:90px;
        overflow: hidden;
        .trasBox {
            position: absolute;
            left: 0;
            top: 0;
            height: auto;
            width: 100%;
            transform-origin: 0 0;
            transition: top 0.8s;
        }
        .qwnum {
            width: 90px;
            height: 90px;
            text-align: center;
        }
    }
</style>